<template>
  <form class="grid mw720 ma h100vh flex fww aic acc" @submit.prevent="handleSubmit">
    <pre class="p2" v-if="response">{{ response }}</pre>
    <div class="grid__main p2">
      <div class="grid__col">
        <h3 class="grid__title">Login</h3>
      </div>
      <div class="grid__col">
        <label class="grid__input--label" for="userEmail">User email</label>
        <input class="grid__input" name="userEmail" type="email" id="userEmail"
               placeholder="john.doe@example.com" v-model="userEmail" required>
      </div>
      <div class="grid__col flex fww">
        <label class="grid__input--label" for="userPass">User password</label>
        <router-link class="mla pointer" :to="{name: 'Forgot Password'}">I forgot password</router-link>
        <div class="grid__field">
          <input class="grid__input" name="userPass" :type="pwdType" id="userPass"
                 placeholder="Password" minlength="12" v-model="userPass">
          <span class="grid__icon" @click="switchVisibility">
            <i class="icon icon--eye" :class="pwdType === 'password' ? 'icon--eye' : 'icon--eye-close'"></i>
          </span>
        </div>
      </div>
      <div class="grid__col">
        <button :disabled="submitted" :class="submitted ? 'is-load' : ''">Login</button>
        <p class="grid__error">{{ errorMessage }}</p>
      </div>
    </div>
  </form>
</template>

<script>
import router from "../../router";
import {authService} from "@/_services";
import {commonHelper} from "@/_helpers/common-helper";

export default {
  data: () => {
    return {
      userEmail: '',
      userPass: '',
      submitted: false,
      response: null,
      pwdType: 'password',
      errorMessage: '',
    }
  },
  methods: {
    switchVisibility() {
      this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
    },
    handleSubmit(e) {
      if (!(this.userEmail && this.userPass)) {
        return;
      }
      this.submitted = true;
      authService.login(this.userEmail, this.userPass).then(
        payload => {
          this.userEmail = '';
          this.userPass = '';
          return router.push('/');
        }
      ).catch((payload) => {
        if ( payload.response.data ) {
          this.errorMessage = payload.response.data.message
        }
      }).finally(() => {
        this.submitted = false;
      });
    },
    logout() {
      authService.logout();
    }
  },
  created() {
    commonHelper.documentTitle( 'QA tool Log In' );
    // reset login status
    this.logout();
  },
  watch: {
    userEmail: function() {
      this.errorMessage = ''
    },
    userPass: function() {
      this.errorMessage = ''
    }
  }
};
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>